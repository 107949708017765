import React, { useState, useEffect } from 'react';
import '../../css/App.css';
import { ErrorToast, WarnToast, InfoToast, CheckToast } from '../../GlobalComponents/CustomToast';
import {
    SimpleGrid, Box, useToast, Flex, Text, SliderThumb, Center, IconButton, Button,
    Textarea,
} from '@chakra-ui/react'
import { BrowserRouter, Routes, Route, Link, Navigate, useNavigate, useLocation, useSearchParams  } from 'react-router-dom';
import MapScreen from '../../GlobalComponents/Map/MapScreen';
import WebsiteHeader from '../../GlobalComponents/WebsiteHeader';
import LieferSortList from './LieferSortList';

const LieferItem = (value, index) => {
    return (
        <Flex bg='#fff' m={1} borderWidth={1} borderRadius={3} borderColor='#000' flex='row' justifyContent='space-between' 
        //backgroundColor={value.Status !== 'D' ? (null) : ('#bbb') }
        >
            <Flex flex='row'>
              <Center bg='#9ace93' w={50} mr={5} text-align='center'>
                    <Text fontSize={32} fontWeight='bold'>{index + 1}</Text>
                </Center>
                <Box flex='4' textAlign='left'>
                    <Text fontWeight='bold'>{value.Name1}; {value.Name2}; {value.Name3}; {value.Name4}</Text>
                    <Text bgColor='white'>{value.LieferID}: {value.Strasse} {value.PLZ} {value.Ort} || Tel: {value.Telefon}</Text>
                </Box>
            </Flex>
        </Flex>
    )
};


export default function EditAbladeSt(props) {

    const [EditHinweis, setEditHinweis] = useState(false);
    const [EditMap, setEditMap] = useState(false);
    const [LieferSort, setLieferSort] = useState(false);

    const [TempHinweis, setTempHinweis] = useState();
    const [tempAblade, setTempAblade] = useState({});
    const toast = useToast();

    const navigate = useNavigate(); //navigate to a certain path
    const [searchParams, setSearchParams] = useSearchParams(); //read and set query-params

    // ---- Style ----

    const SubHeaderStyle = {fontSize: '20px', fontWeight: 'bold'};

    //------------------------------------------
    useEffect(() => {

        //console.log(props.AbladeSt);
        if (props.AbladeSt !== undefined) {
            setTempAblade(JSON.parse(JSON.stringify(props.AbladeSt)));
            setTempHinweis(props.AbladeSt.AbladeHinweis);
        } else {
            readQueryParams();
        }
        
    }, [props.AbladeSt])

    function readQueryParams(){

        const Ablade = searchParams.get('ablade');

		if(Ablade !== null){
                props.EditAbladeCall('setIndex', Ablade);
		}
	}
    async function MapCall(comand, value1, value2) {
        if (comand === 'newCords') {
            changeTempCords(value1);
        }
        if (comand === 'setMap') { props.EditAbladeCall('setMap', value1) }
    }
    async function _sortLieferCall(comand, value1, value2) {
        if (comand === 'saveSort') {
            //setSort('standart')
            props._sortierCall('save');
        }
        if (comand === 'saveLS') {
            props.EditAbladeCall('saveLS', props.AbladeSt.AbladeID);
        }
        if (comand === 'explodeAblade') {
            var result = await props.EditAbladeCall('explodeAblade',props.AbladeSt.Manifest, props.AbladeSt.AbladeID)

            if (result === 'stop') {
                toast({ render: () => ( <WarnToast title='Verteilung Unzulässig!' descr="Eine Lieferstelle, oder die Abladestelle wurden Abgeschlossen"/> ), status: 'warn', duration: 7000, isClosable: true, position: 'top-right', })
            } else if (result === 'error')  {
                toast({ render: () => ( <ErrorToast title='Verteilung Fehlgeschlagen!' descr="Fehler beim Abruf"/>), status: 'error', duration: 5000, isClosable: true, position: 'top-right', })
            }
            return result;
        }
    }
    function resetHinweis() {

        const unlinkedAblade = {...props.AbladeSt};
        setTempHinweis(unlinkedAblade.AbladeHinweis);
        setEditHinweis(false);

    }
    function saveHinweis(type) {

        props.EditAbladeCall('saveHW', props.AbladeSt.AbladeID, TempHinweis, type);
        setEditHinweis(false)
    }
    function _LieferSort(oldIndex, newIndex) {
        
        var AbladeST = Object.assign( {}, tempAblade);
        var sortItem = AbladeST.Lieferstellen[oldIndex]
        
        AbladeST.Lieferstellen.splice(oldIndex, 1)
        AbladeST.Lieferstellen.splice(newIndex, 0, sortItem)

        setTempAblade(AbladeST);

    }
    function _LieferSortRevert(){

        const unlinkedAblade = JSON.parse(JSON.stringify(props.AbladeSt))
        // Lieferstellen become reference of main Lieferstellen, if not deep copied;

        var Ablade = {...tempAblade}
        Ablade.Lieferstellen = unlinkedAblade.Lieferstellen;
        Ablade.Longitude = unlinkedAblade.Longitude;
        setTempAblade(Ablade);

        setLieferSort(false)
    }
    function _LieferSortSave(){

        var Lieferstellen = [...tempAblade.Lieferstellen]

        props.EditAbladeCall('saveLS', props.AbladeSt.AbladeID, Lieferstellen);

        setLieferSort(false)
    }
    function changeTempCords(LatLng) {

        var Ablade = {...tempAblade}
        Ablade.Latitude = LatLng.lat;
        Ablade.Longitude = LatLng.lng;
        setTempAblade(Ablade);
    } 
    function resetTempCords() {

        var Ablade = {...tempAblade}
        Ablade.Latitude = props.AbladeSt.Latitude;
        Ablade.Longitude = props.AbladeSt.Longitude;
        setTempAblade(Ablade);
        setEditMap(false);
    }
    function saveTempCords(){

        var Ablade = {...tempAblade}
        var latlng = { lat: Ablade.Latitude, long: Ablade.Longitude };
        props.EditAbladeCall('save', latlng);
        setEditMap(false);
    }

    if ( props.AbladeSt !== undefined) return (
        <Flex flexDir='column'>
            <WebsiteHeader Text='TourPortal / Manifest Sortieren / Abladestelle Bearbeiten' onClick={() => props.EditAbladeCall('close')} server={props.server} User={props.ActiveUser} />
            <Flex height='93vh' flexShrink={1} backgroundColor='#ccc' p={1} flexDirection='row'>
           
                <Flex flex={1.2} flexDirection='column' mr={1}>

                    <Flex flex={1} >

                        <Flex flex={1} borderWidth={1} borderRadius={3} borderColor='#000' mb={1} mr={1} bg='#fff' padding={2} overflow='auto' flexDirection='column'>

                            <Text fontSize={25} fontWeight={'bold'}>Abladestelle: {props.AbladeSt.AbladeID}</Text>
                            <Flex m={2}>
                                <Flex flexDirection='column' marginRight={10}>
                                    <Text fontSize={20} my={-1}>Bezeichnung:</Text>
                                    <Text fontSize={20} my={-1}>Straße:</Text>
                                    <Text fontSize={20} my={-1}>Ort:</Text>
                                    <Text fontSize={20} my={-1}>PLZ:</Text>
                                    <Text fontSize={20} my={-1}>Lieferstellen:</Text>
                                    <Text fontSize={20} my={-1}>Pakete / Papier:</Text>
                                </Flex>
                                <Flex flexDirection='column' marginRight={10}>
                                    <Text fontSize={20} my={-1}>{props.AbladeSt.Bezeichnung}</Text>
                                    <Text fontSize={20} my={-1}>{props.AbladeSt.Straße}</Text>
                                    <Text fontSize={20} my={-1}>{props.AbladeSt.Ort}</Text>
                                    <Text fontSize={20} my={-1}>{props.AbladeSt.PLZ}</Text>
                                    <Text fontSize={20} my={-1}>{props.AbladeSt.Liefer_Anzahl}</Text>
                                    <Text fontSize={20} my={-1}>{props.AbladeSt.Pakete_Anzahl} / {props.AbladeSt.Papier_Anzahl}</Text>
                                </Flex>
                            </Flex>

                        </Flex>
                        <Flex flex={1} borderWidth={1} borderRadius={3} borderColor='#000' mb={1} bg='#fff' padding={2} overflow='auto' flexDirection='column' alignItems='center'>
                            <Text style={SubHeaderStyle} width='98%'>Abladehinweis:</Text>

                            { EditHinweis ? (
                                <Flex m={1} flex={1} width='93%'>
                                    <Button m={2} colorScheme='twitter' onClick={() => resetHinweis() }>Abbrechen</Button>
                                    <Flex m={2} height='40px' >
                                        <Flex bgColor='#db6a00' alignItems='center' justifyContent='center' px={2} borderRadius='5px 0px 0px 5px' >
                                            <Text style={{color: '#fff', fontSize: '16px', fontWeight: 600}}>Speichern: </Text>
                                        </Flex>
                                        <Button onClick={() => saveHinweis('once')} colorScheme='orange' borderRadius='0px 0px 0px 0px' isDisabled={!props.UserRights.tour_manage} ml='2px'>Einmalig</Button>
                                        <Button onClick={() => saveHinweis('all')} colorScheme='orange' borderRadius='0px 5px 5px 0px' ml='2px'>Permanent</Button>
                                    </Flex>
                                </Flex>
                                ) : (
                                <Flex m={1} flex={1} width='93%'>
                                    <Button m={2} colorScheme='twitter' isDisabled={!props.UserRights.tour_manage} 
                                    onClick={() => setEditHinweis(true)}>Hinweis Bearbeiten</Button>
                                </Flex>
                            )}

                            <Textarea flex={1} my={5} maxWidth='90%' //value={props.AbladeSt.Bezeichnung} 
                            resize='none' isDisabled={!EditHinweis} value={TempHinweis} onChange={(e)=> setTempHinweis(e.target.value)}
                            ></Textarea>

                        </Flex>
                        
                    </Flex>

                    <Flex flex={2.5} borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' overflow='auto' flexDirection='column' >

                        <Flex flex={1} justifyContent='space-between'>
                        <Text style={SubHeaderStyle} ml={2} mt={2}>Koordinaten:</Text>
                            { EditMap ? (
                                <Flex m={1}>
                                    <Button m={2} colorScheme='twitter' onClick={() => resetTempCords() }>Abbrechen</Button>
                                    <Button m={2} colorScheme='orange' isDisabled={!props.UserRights.tour_manage} 
                                    onClick={() => saveTempCords()}>Position Speichern</Button>
                                </Flex>
                                ) : (
                                <Flex m={1}>
                                    <Button m={2} colorScheme='twitter' isDisabled={!props.UserRights.tour_manage} 
                                    onClick={() => setEditMap(true)}>Position Bearbeiten</Button>
                                </Flex>
                            )}
                        </Flex>

                    { tempAblade.AbladeID !== undefined ? (
                        <MapScreen TourData={[tempAblade]} Route={[]} RouteData={[]} editMap={EditMap} MapCall={MapCall} ActiveUser={props.ActiveUser}/>
                    ):(null)}
                    </Flex>

                </Flex>

                <Flex flex={1} borderWidth={1} borderRadius={3} borderColor='#000' bg='#fff' padding={2} overflow='auto' flexDirection='column' minWidth='400px'>
                    <Flex justifyContent='space-between'>
                        <Text style={SubHeaderStyle}>Lieferstellen:</Text>
                        { LieferSort ? (
                            <Flex >
                                <Button m={2} colorScheme='twitter' onClick={() => _LieferSortRevert()}>Abbrechen</Button>
                                <Button m={2} colorScheme='orange' onClick={() => _LieferSortSave()}>Sortierung Speichern</Button>
                            </Flex>
                        ) : (
                            <Flex style={{ justifyContent: 'space-between'}}>
                                <Button m={2} colorScheme='twitter' onClick={() => setLieferSort(true) }>Lieferstellen Sortieren</Button>
                                <Button m={2} colorScheme='orange' onClick={() => null }>Lieferstellen Aufteilen</Button>
                            </Flex>
                        ) }
                    </Flex>
                    { !LieferSort ? (
                        
                        <Box style={{margin: '20px'}}>
                            { tempAblade.Lieferstellen !== undefined ? (
                            tempAblade.Lieferstellen.map((item, index) => LieferItem(item, index))
                            ):(null)}
                        </Box>
                        ):(
                        <LieferSortList Lieferstellen={tempAblade.Lieferstellen} _LieferSort={_LieferSort}/>
                    )}
                </Flex>
            </Flex>
        </Flex >
    );
}

