import React, { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text, Button, Flex, useToast, Center } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'
import Cookies from 'universal-cookie';

import WebsiteHeader from '../GlobalComponents/WebsiteHeader';
import TableHead from '../GlobalComponents/TableHead';
import MapScreen from '../GlobalComponents/Map/MapScreen';

import CreateDevToken from './CreateDevToken';
import { ErrorToast, WarnToast, CheckToast } from '../GlobalComponents/CustomToast';

export default function DevicePannel(props) {

    const [Devices, setDevices] = useState([])
    const [TokenTable, setTokenTable] = useState([]);
    const [DeviceTable, setDeviceTable] = useState([]);

    const [MapData, setMapData] = useState([]);
    const [AddDrawer, setAddDrawer] = useState(false);

    const toast = useToast();
    const toastIdRef = React.useRef();

    useEffect(() => {
        _fetchDevices();
    }, []);

    async function AddDrawerCall(comand, value1) {
        if (comand === 'back') {
            setAddDrawer(false)
        }

        if (comand === 'request') {

            setAddDrawer(false)
            NewDevToken(value1);
        }
    }

    async function sendData(target, params, json = {}) {

        var url = props.server + '/API/index.php/' + target + params;
        const cookies = new Cookies();
        var UserToken = cookies.get('LoginToken');
        json.Token = UserToken;
        //console.log(json)
        try {
            const response = await fetch(url, {
                method: 'POST',
                //headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': 'Basic VHJhdmVsZXI6VGhpczEhSXMyIlRoZTPCp1Bhc3N3b3JkNCQ=' },
                body: JSON.stringify(json)
            });
            const results = await response.json();
            if (results === 'invalid') {
                console.log(target)
                props.searchCall('logOut')
            } else if (results.AuthKey === 'noRight') {
                toast({ render: () => ( <WarnToast title='Berechtigungen Fehlerhaft' descr='Sie besitzen keine Berechtigung für diese Aktion!'/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
                props.searchCall('newRights', results.Rights)
            } else return results
        } catch (error) { console.log(error) }
    }
    async function _fetchDevices() { //Load all Tables
        var data = await sendData('Web_LoadDevices', '', {})
        console.log(data);
        if (typeof data === 'object' && data !== null) {

            setTokenTable(data.TokenTable)

            DevicePositionData(data.DeviceTable);

        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Tabellendaten sind Fehlerhaft!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function TokenStatus(Token, Active) { //set Token status

        console.log(typeof Active + ': ' + Active)

        if (Active === true) {
            var Time = null
        } else {
            var Time = true
        }
        var method = 'Web_ResetTokenTime';
        var body = { ChangeToken: Token, Timestamp: Time }
        var result = await sendData(method, '', body)
        //console.log(result)
        if (result !== false && result !== 'invalid') {

            //console.log(result)

            _fetchDevices()
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Token konnte nicht erneuert werden!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top', })
    }
    async function NewDevToken(DeviceID) { //set Token status

        var method = 'Web_CreateDevToken';
        var body = { DeviceID: DeviceID }
        var result = await sendData(method, '', body)
        console.log(result)
        if (result !== false && result !== 'invalid' && result !== 'error') {

            _fetchDevices()
        } else toast({ render: () => ( <ErrorToast title='Abruf fehlgeschlagen!' descr="Dev-Token wurde nicht erstellt!"/>), status: 'error', duration: 5000, isClosable: true, position: 'top' })
    }
    async function DevicePositionData(DevicePos) {

        for (let i = 0; i < DevicePos.length; i++) {

            DevicePos[i].Latitude = DevicePos[i].Last_Latitude
            DevicePos[i].Longitude = DevicePos[i].Last_Longitude
            DevicePos[i].AbladeID = DevicePos[i].DeviceID
            DevicePos[i].Index = i + 1
        }

        setDeviceTable(DevicePos)
        setMapData(DevicePos);
    }
    function OnTokenSetPress(Token, SetToken, DeviceID) {

        if (Token) {

            if (SetToken === "true") { var bool = true } else { var bool = false }
            TokenStatus(Token, bool)
        } else {
            NewDevToken(DeviceID);
        }
    }
    async function MapCall(comand, value1, value2) {
        if (comand === 'setMap') {
            props.searchCall('setMap', value1)
        }
    }
    const columns = [
        { label: 'Gerät', accessor: 'DeviceID', sortable: true, width: 120 },
        { label: 'Nutzer', accessor: 'User_Name', sortable: true, width: 120 },
        { label: 'Login Typ', accessor: 'Acces_Type', sortable: true, width: 120 },
        { label: 'letzte Erneuerung', accessor: 'Timestamp', sortable: true, width: 120 },
        { label: '', accessor: 'Buttons', sortable: false, width: 60 }
    ]
    const acolumns = [
        { label: '*', accessor: 'Index', sortable: false, width: 30, },
        { label: 'Geräte ID', accessor: 'DeviceID', sortable: true, width: 120, },
        { label: 'Gerätename', accessor: 'Device_Name', sortable: true, width: 80, },
        { label: 'IP Adresse', accessor: 'IP_Addr', sortable: true, width: 80, },
        { label: 'Benutzer', accessor: 'Last_User', sortable: true, width: 100, },
        { label: 'Aktualisiert', accessor: 'Last_Time', sortable: true, width: 100, },
        { label: 'Dev-Token', accessor: 'DevTokenSet', sortable: false, width: 60, }
    ]
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...TokenTable].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTokenTable(sorted);
        }
    };
    const sortDevice = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...DeviceTable].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setDeviceTable(sorted);
        }
    };
    return (
        <Flex flexDirection='column'>
            <WebsiteHeader Text='TourPortal / Geräte verwalten' onClick={() => props.searchCall('back')}
                server={props.server} User={props.ActiveUser} />
            <Flex height='93vh' backgroundColor='#ccc' p={1}>

                <Flex flex={1} flexDirection='column' overflow='auto'>

                    <Box flex={1} mr={1} borderWidth={1} borderRadius={3} borderColor='#000' bgColor='#fff' overflowY='auto' padding={2}>

                        <Table size="small">
                            <TableHead {...{ columns, handleSorting }} />
                            <Tbody>
                                {TokenTable.map((row) => {
                                    return (
                                        <Tr key={row.Tocken}>
                                            {columns.map(({ accessor }) => {
                                                const tData = row[accessor];
                                                return (<React.Fragment key={accessor}>
                                                    <Td textAlign="center" borderWidth={1} bgColor={'#fff'} h='50px' bg={row.Active ? '#b8edc6' : '#fcb1b1'}>

                                                        {accessor === 'Manifest' && tData !== false ? (
                                                            <Button colorScheme='blue'>{tData}</Button>
                                                        ) : (
                                                            <Text noOfLines={1}>{tData}</Text>
                                                        )}
                                                        {accessor === 'Buttons' ? (
                                                            <Button colorScheme='blue' onClick={() => TokenStatus(row.Tocken, row.Active)}>
                                                                {row.Active ? ('Inaktiv Setzen') : ('Aktiv Setzen')}
                                                            </Button>
                                                        ) : (null)}
                                                    </Td>
                                                    <Td className="columnResizer" />
                                                </React.Fragment>);
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>

                    </Box>

                    <Box flex={1} mr={1} mt={1} borderWidth={1} borderRadius={3} borderColor='#000' bgColor='#fff' overflowY='auto' padding={2}>

                        <Table size="small" variant='striped' colorScheme='blackAlpha'>
                            <TableHead {...{ columns: acolumns, handleSorting: sortDevice }} />
                            <Tbody>
                                {DeviceTable.map((row) => {
                                    return (
                                        <Tr key={row.DeviceID}>
                                            {acolumns.map(({ accessor }) => {
                                                const tData = row[accessor];
                                                return (<React.Fragment key={accessor}>
                                                    <Td textAlign="center" borderWidth={1} h='50px' bgColor={'#fff'}>

                                                        {accessor === 'DevTokenSet' && tData !== false ? (
                                                            <Button colorScheme='blue'
                                                                onClick={() => OnTokenSetPress(row.DevToken, row.DevTokenSet, row.DeviceID)}
                                                            >{tData}</Button>
                                                        ) : (
                                                            <Text noOfLines={1}>{tData}</Text>
                                                        )}

                                                    </Td>
                                                    <Td className="columnResizer" />
                                                </React.Fragment>);
                                            })}
                                        </Tr>
                                    );
                                })}
                            </Tbody>
                        </Table>

                        <Button colorScheme='blue' mt='10px' onClick={() => setAddDrawer(true)}>Dev-Token Hinzufügen</Button>

                    </Box>

                </Flex>
                <Flex flex={1} flexDirection='column' overflow='auto'>

                    <Box flex={2} borderWidth={1} borderRadius={3} borderColor='#000'>
                        <MapScreen TourData={MapData} RouteObj={{}} editMap={false} ActiveUser={props.ActiveUser} MapCall={MapCall}/>
                    </Box>
                    <Box flex={1}></Box>

                </Flex>

            </Flex >

            <CreateDevToken isOpen={AddDrawer} AddDrawerCall={AddDrawerCall} />

        </Flex>
    )
}