import React, { useState, useEffect } from 'react';
import { Button, Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Text } from '@chakra-ui/react'
import { useTable, useSortBy } from 'react-table'

import TableHead from '../../GlobalComponents/TableHead';
import HistoryBox from '../HistoryBox';

export default function AccordionPakete(props) {
    const [dummy, setdummy] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [history, setHistory] = useState([]);
    const [historyOpen, setHistoryOpen] = useState(false);
    const [image, setImage] = useState('');

    useEffect(() => {
        setTableData(props.LieferData)
        //if (props.LieferData.length !== 0) { setCurent(props.LieferData[0].Paketnummer) }
    },[props.LieferData]);


    const columns = [
        {
            label: 'Paketnummer',
            accessor: 'Paketnummer',
            sortable: true,
            width: 80,
        },
        {
            label: 'Lieferschein',
            accessor: 'Lieferschein',
            sortable: true,
            width: 80,
        },
        /*{
            label: 'LieferID',
            accessor: 'LieferID',
            sortable: true,
            width: 80,
        },*/
        {
            label: 'Kennzeichen',
            accessor: 'Kennzeichen',
            sortable: true,
            width: 60,
        },
        {
            label: 'Druckdatum',
            accessor: 'Druckdatum',
            sortable: true,
            width: 40,
        },
        {
            label: 'gesKoPA',
            accessor: 'gesKoPa',
            sortable: true,
            width: 40,
        },
        {
            label: 'Status',
            accessor: 'Status',
            sortable: true,
            width: 40,
        },
        {
            label: 'Zustell_Status',
            accessor: 'Zustell_Status',
            sortable: true,
            width: 50,
        },
        {
            label: 'Zustell_Zeit',
            accessor: 'Zustell_Zeit',
            sortable: true,
            width: 100,
        }
    ]
    async function _fetchData(ManifestNr, PaketNr) {
        //console.log(props.server)
        var url = props.server + '/API/index.php/GET_PaketHistory?ManifestNr=' + ManifestNr + '&PaketNr=' + PaketNr
        //var url = 'https://tourmanager.kreller.de/API/index.php/GET_PaketHistory?ManifestNr=' + ManifestNr + '&PaketNr=' + PaketNr
        //console.log(url)
        try {
            const response = await fetch(url);
            const data = await response.json();
            ////console.log(data)
            setHistory(data);

        } catch (error) {
            //console.log(error)
            alert('Verbindung Fehlgeschlagen!');
        }
        ////console.log(TourData)
    }
    const handleSorting = (sortField, sortOrder) => {
        if (sortField) {
            const sorted = [...tableData].sort((a, b) => {
                if (a[sortField] === null) return 1;
                if (b[sortField] === null) return -1;
                if (a[sortField] === null && b[sortField] === null) return 0;
                return (
                    a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
                        numeric: true,
                    }) * (sortOrder === "asc" ? 1 : -1)
                );
            });
            setTableData(sorted);
        }
    };
    const colorMaker = (Status) => {
        switch (Status) {
            case 'done':
                return ('#c2e9cc')
            case 'accept':
                return ('#fdd386')
            case 'return':
                return ('#ffc6ad')
            case 'broken':
                return ('#ffadad')
            default:
                return ('#fff')
        }
    }
    async function handleStatus(PaketNr, Image) {

        await _fetchData(props.ManifestNr, PaketNr)
        setImage(Image)
        setHistoryOpen(true)


    }
    function PaketCall() {
        setHistoryOpen(false)
    }
    return (

        <Box overflowY="auto">
            
            <Table size="small">
                <TableHead {...{ columns, handleSorting }} />
                <Tbody>
                    {tableData.map((row) => {
                        return (
                            <Tr key={row.Paketnummer} onClick={() => props.paketCall('setCurent', row.Paketnummer)}
                                bg={() => colorMaker(row.Status)}
                                border={props.curent == row.Paketnummer ? '2px dashed #0073ff' : null}
                            >
                                {columns.map(({ accessor }) => {
                                    const tData = row[accessor];
                                    return (<React.Fragment key={accessor}>
                                        <Td textAlign="center" borderWidth='1.5px' borderColor='#e6e6e6' >
                                            {accessor !== 'Status' ? (
                                                <Text noOfLines={1}>{tData}</Text>
                                            ) : (
                                                <Button borderWidth="1px" borderColor="black" borderRadius={3} bgColor="#fff" h='6' w='100%' onClick={() => [handleStatus(row.Paketnummer, row.Image)]}>{tData}</Button>
                                            )}
                                        </Td>
                                        <Td className="columnResizer" />
                                    </React.Fragment>);
                                })}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
            <HistoryBox isOpen={historyOpen} paketCall={() => PaketCall()} Image={image} History={history} server={props.server} 
            curentPaket={props.curent} />
        </Box>

    );
}