import React, { useState, useEffect } from 'react';
import { Button, Table, Thead, Tbody, Tr, Th, Td, chakra, Box, Icon, Image, Flex, Text, Center } from '@chakra-ui/react'

import { FiPackage } from "react-icons/fi";
import { useTable, useSortBy } from 'react-table'
import BaseModal from '../GlobalComponents/BaseModal';


export default function HistoryBox(props) {
    const [liveImage, setLiveImage] = useState('');
    useEffect(() => {
        setLiveImage(props.Image);
    },[])

    return (
        <BaseModal isOpen={props.isOpen} onClose={() => props.paketCall()} style={{display: 'flex', flex: 1, alignItems: 'center', flexDirection: 'column', padding: 5, maxWidth: '700px', maxHeight: '450px' }}>

                <Text fontSize={25} fontWeight='bold'>Paketnummer: {props.curentPaket}</Text>
                <Box display='flex' flexDirection='row' mt={3} flex={1} >
                    <Center borderWidth={3} margin={1} w='250px' h='370px' textAlign='center'>


                        {liveImage !== null && liveImage !== '' ? (
                            <Image src={props.server + '/API/tmp/' + liveImage} alt={liveImage} key={liveImage} objectFit='contain' />
                        ) : (
                            <Icon as={FiPackage} color='#aaa' boxSize={150}/>
                        )}
                    </Center>
                    <Box borderWidth={3} margin={1} flex={1} overflow="auto" h='370px' pr={5}


                    >
                        {props.History.map((item) => {

                            return (
                                <Box display='flex' flexDir='column' bg='#ddd' key={item.ID} minWidth='380px' mb={1} px={1}>
                                    <Text fontWeight='bold'>{item.Änderungsdatum}</Text>
                                    <Text>Manifest: {item.Manifest} | {item.Status} | {item.Zustell_Status}</Text>
                                    {item.ImageUri ? (
                                        <Button h='6' w='40%' m={1} onClick={() => setLiveImage(item.ImageUri)}
                                        >{item.ImageUri}</Button>
                                    ) : (null)}
                                </Box>
                            );
                        })}
                    </Box>
                </Box>

        </BaseModal>
    );
}